<template>
  <div>
    <!-- ANCHOR Filters -->
    <SaleReportListFilters
      ref="saleReportListFilters"
      :fix-flight-type="fixFlightType"
      :init-filter-is-invoiced="initFilterIsInvoiced"
      :search-text.sync="searchText"
      @refetch-data-with-filters="refetchData"
      @refetch-data-without-filters="clearFilter"
    />

    <!-- Table Container Card -->
    <b-card
      style="max-height: 100vh"
      class="mt-50"
      no-body
    >
      <!-- SECTION Table Top -->
      <div class="m-1">
        <div class="d-flex justify-content-between">
          <!-- ANCHOR Per Page -->
          <div
            md="auto"
            class="px-0 mr-md-1"
          >
            <v-select
              v-model="sizePerPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="sizePerPageLgOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            >
              <template #option="data">
                <span>
                  {{ data.label }}
                </span>
              </template>

              <template #selected-option="data">
                <span>
                  {{ data.label }}
                </span>
              </template>

              <template #no-options>
                {{ $t('noOptions') }}
              </template>
            </v-select>
          </div>

          <!-- ANCHOR Button -->
          <div>
            <div class="d-none d-md-block">
              <b-button
                v-if="canActionSaleReportManually && getEnv !== 'production'"
                variant="info"
                class="mr-1 px-75"
                :to="{ name: 'apps-saleReport-create-manual'}"
              >
                <span class="text-nowrap">
                  <feather-icon
                    icon="PlusIcon"
                    size="16"
                    class="cursor-pointer"
                  />
                  {{ $t('saleReport.createManually.title') }}
                </span>
              </b-button>
              <!-- <b-button
                variant="flat-info"
                class="mr-1 px-75"
                :to="{ name: 'apps-saleReport-import'}"
              >
                <span class="text-nowrap">
                  <feather-icon
                    icon="DownloadIcon"
                    size="16"
                    class="cursor-pointer"
                  />
                  {{ $t('import') }}
                </span>
              </b-button> -->

              <b-button
                variant="flat-info"
                class="px-75"
                @click="confirmExport()"
              >
                <span class="text-nowrap">
                  <feather-icon
                    icon="DownloadIcon"
                    size="16"
                    class="cursor-pointer"
                  />
                  {{ $t('export') }}
                </span>
              </b-button>
            </div>

            <!-- Dropdown -->
            <div class="d-block d-md-none">
              <b-dropdown
                variant="info"
                class="m-lg-2"
                boundary="window"
                right
              >
                <template #button-content>
                  <feather-icon
                    icon="MenuIcon"
                    size="16"
                  />
                  <span class="pl-50">{{ $t('agency.moreDropdown.title') }}</span>
                </template>

                <b-dropdown-item
                  v-if="canActionSaleReportManually && getEnv !== 'production'"
                  :to="{ name: 'apps-saleReport-create-manual'}"
                >
                  {{ $t('saleReport.createManually.title') }}
                </b-dropdown-item>

                <b-dropdown-item @click="confirmExport()">
                  {{ $t('agency.moreDropdown.export') }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>
      </div>
      <!-- !SECTION -->

      <!-- SECTION Table content -->
      <b-overlay
        :show="loading"
        rounded="sm"
        no-fade
        variant="transparent"
        :opacity="0.5"
      >
        <b-table
          ref="refSaleReportListTable"
          style="max-height: 70vh; margin-bottom: 0;"
          sticky-header
          class="position-relative"
          :items="fetchSaleReports"
          responsive
          bordered
          :fields="tableColumns"
          primary-key="id"
          table-class="table-sales-report"
          :sort-by.sync="sortBy"
          show-empty
          small
          :empty-text="$t('noMatchingResult')"
          :sort-desc.sync="isSortDirDesc"
          no-border-collapse
        >
          <!-- ANCHOR Header -->
          <template
            v-for="column in tableColumns"
            #[`head(${column.key})`]="data"
          >
            <div
              :key="column.label"
              class="text-dark text-center"
            >
              <!-- ANCHOR Checkbox Sellect All -->
              <div
                v-if="column.key === 'checkbox'"
                @click.prevent.stop="onHandleSelectAllOrSelectNone()"
              >
                <b-form-checkbox
                  v-show="!showCheckboxSelectAll"
                  :indeterminate="true"
                  inline
                />
                <b-form-checkbox
                  v-show="showCheckboxSelectAll"
                  inline
                  :checked="isSelectAll"
                  :disabled="isAlreadySelectAll"
                />
              </div>
              <template v-else>
                <span
                  v-b-tooltip.hover.window
                  class="text-truncate"
                  :title="$t(`saleReport.columns.${data.label}`)"
                >
                  {{ $t(`saleReport.columns.shortTitle.${data.label}`) }}
                </span>
              </template>
            </div>
          </template>

          <template
            v-for="column in tableColumns"
            #[`cell(${column.key})`]="{ item }"
          >
            <!-- item {{ item[column.key] }} -->
            <div
              :key="column.key"
              class="text-nowrap"
              :class="{
                'text-right': typeof item[column.key] === 'number',
              }"
            >
              {{ item[column.key] }}
            </div>
          </template>

          <!-- ANCHOR Checkbox [For Invoice Only] -->
          <template
            v-if="useForInvoice"
            #cell(checkbox)="{ item }"
          >
            <b-form-checkbox
              class="mr-0 ml-50 mt-50"
              name="check-box"
              inline
              :disabled="isAlreadySelected(item)"
              :checked="isAlreadySelected(item) || isChecked(item.id)"
              @change="toggleSelectItem(item)"
            />
          </template>

          <!-- ANCHOR ticketName [For Invoice Only] -->
          <template
            v-if="useForInvoice"
            #cell(ticketName)="{ item }"
          >
            {{ item.ticketTrips }}
            <span v-show="showTicketNumber">
              {{ item.ticketNumber || item.bookingCode }}
            </span>
          </template>

          <!-- ANCHOR no. -->
          <template #cell(no)="data">
            <div class="mb-0 text-nowrap d-flex-center">
              {{ data.index + dataMeta.from }}
            </div>
          </template>

          <!-- ANCHOR source -->
          <template #cell(source)="{ item: { source } }">
            {{ $te(`flight.sourceName.${source}`) ? $t(`flight.sourceName.${source}`) : source }}
          </template>

          <!-- ANCHOR issueDate -->
          <template #cell(issueDate)="{ item: { issueDate } }">
            {{ replaceText(convertISODateTime(issueDate).dateTime) }}
          </template>

          <!-- ANCHOR flightType -->
          <template #cell(flightType)="{ item: { flightType } }">
            {{ $te(`saleReport.${flightType}`) ? $t(`saleReport.${flightType}`) : flightType }}
          </template>

          <!-- ANCHOR trips -->
          <template #cell(trips)="{ item: { trips } }">
            <div v-if="trips">
              <div
                v-for="(trip, tripIndex) in convertShortenTrips(trips)"
                :key="tripIndex"
              >
                {{ trip }}
              </div>
            </div>
          </template>

          <!-- ANCHOR flightDates -->
          <template #cell(flightDates)="{ item: { flightDates } }">
            {{ replaceText(flightDates) }}
          </template>

          <!-- ANCHOR fareBasisCode -->
          <template #cell(fareBasisCode)="{ item: { fareBasisCode } }">
            {{ replaceText(fareBasisCode) }}
          </template>

          <!-- ANCHOR bookingDate -->
          <template #cell(bookingDate)="{ item }">
            <div>
              {{ replaceText(convertISODateTime(item.bookingDate).dateTime) }}
            </div>
          </template>

          <!-- ANCHOR ticketType -->
          <template #cell(ticketType)="{ item }">
            <div class="text-nowrap">
              {{ $te(`saleReport.${item.ticketType}`) ? $t(`saleReport.${item.ticketType}`) : item.ticketType }}
            </div>
          </template>

          <!-- ANCHOR balanceAdjustment -->
          <template #cell(balanceAdjustment)="{ item: { balanceAdjustment} }">
            <div class="text-right text-nowrap">
              {{ formatVnCurrency(balanceAdjustment) }}
            </div>
          </template>

          <!-- ANCHOR priceTicket -->
          <template #cell(priceTicket)="{ item: { priceTicket } }">
            <div class="text-right text-nowrap">
              {{ formatVnCurrency(priceTicket) }}
            </div>
          </template>

          <!-- ANCHOR collection fee -->
          <template #cell(collectionFee)="{ item: { collectionFee } }">
            <div class="text-right text-nowrap">
              {{ formatVnCurrency(collectionFee) }}
            </div>
          </template>

          <!-- ANCHOR tax -->
          <template #cell(tax)="{ item }">
            <div class="text-right">
              {{ formatVnCurrency(item.tax) }}
            </div>
          </template>

          <!-- ANCHOR otherTax -->
          <template #cell(otherTax)="{ item }">
            <div class="text-right">
              {{ formatVnCurrency(item.otherTax) }}
            </div>
          </template>

          <!-- ANCHOR fee -->
          <template #cell(fee)="{ item }">
            <div class="text-right">
              {{ formatVnCurrency(item.fee) }}
            </div>
          </template>

          <!-- ANCHOR feeIn -->
          <template #cell(feeIn)="{ item }">
            <div class="text-right">
              {{ formatVnCurrency(isRoleF2 ? item.feeOut : item.feeIn) }}
            </div>
          </template>

          <!-- ANCHOR feeOut -->
          <template #cell(feeOut)="{ item }">
            <div class="text-right">
              {{ formatVnCurrency(isRoleF2 ? item.feeIn : item.feeOut ) }}
            </div>
          </template>

          <!-- ANCHOR feeService -->
          <template #cell(feeService)="{ item }">
            <div class="text-right">
              {{ formatVnCurrency(item.feeService) }}
            </div>
          </template>

          <!-- ANCHOR commissionPrepaid -->
          <template #cell(commissionPrepaid)="{ item }">
            <div class="text-right">
              {{ formatVnCurrency(item.commissionPrepaid) }}
            </div>
          </template>
          <!-- ANCHOR commissionPrepaidIn -->
          <template #cell(commissionPrepaidIn)="{ item }">
            <div class="text-right">
              {{ formatVnCurrency(item.commissionPrepaidIn) }}
            </div>
          </template>
          <!-- ANCHOR commissionPrepaidOut -->
          <template #cell(commissionPrepaidOut)="{ item }">
            <div class="text-right">
              {{ formatVnCurrency(item.commissionPrepaidOut) }}
            </div>
          </template>

          <!-- ANCHOR unpaidCommission -->
          <template #cell(unpaidCommission)="{ item }">
            <div class="text-right">
              {{ formatVnCurrency(item.unpaidCommission) }}
            </div>
          </template>

          <!-- ANCHOR unpaidCommissionIn -->
          <template #cell(unpaidCommissionIn)="{ item }">
            <div class="text-right">
              {{ formatVnCurrency(item.unpaidCommissionIn) }}
            </div>
          </template>

          <!-- ANCHOR unpaidCommissionOut -->
          <template #cell(unpaidCommissionOut)="{ item }">
            <div class="text-right">
              {{ formatVnCurrency(item.unpaidCommissionOut) }}
            </div>
          </template>

          <!-- ANCHOR commission -->
          <template #cell(commission)="{ item }">
            <div class="text-right">
              {{ formatVnCurrency(item.commission) }}
            </div>
          </template>

          <!-- ANCHOR tradeCreditors -->
          <template #cell(tradeCreditors)="{ item }">
            <div class="text-right">
              {{ formatVnCurrency(item.tradeCreditors) }}
            </div>
          </template>

          <!-- ANCHOR receivables -->
          <template #cell(receivables)="{ item }">
            <div class="text-right">
              {{ formatVnCurrency(item.receivables) }}
            </div>
          </template>

          <!-- ANCHOR profit: Lợi nhuận -->
          <template #cell(profit)="{ item }">
            <div class="text-right">
              {{ formatVnCurrency(item.profit) }}
            </div>
          </template>

          <!-- ANCHOR totalPayment -->
          <template #cell(totalPayment)="{ item: { totalPayment } }">
            <div class="font-weight-bold text-right">
              {{ formatVnCurrency(totalPayment) }}
            </div>
          </template>

          <!-- ANCHOR createdAt -->
          <template #cell(createdAt)="data">
            <div class="mb-0">
              {{ replaceText(dateTime(data.item.createdAt)) }}
            </div>
          </template>

          <!-- ANCHOR updatedAt -->
          <template #cell(updatedAt)="data">
            <div class="mb-0">
              {{ replaceText(dateTime(data.item.updatedAt)) }}
            </div>
          </template>
          <!-- ANCHOR agency -->
          <template #cell(agency)="data">
            <div class="mb-0 text-nowrap">
              {{ data.item.agency.agencyCode.toUpperCase() }}
            </div>
          </template>

          <!-- ANCHOR booker -->
          <template #cell(booker)="data">
            <div
              v-if="data.item.booker"
              class="mb-0 text-nowrap"
            >
              {{ data.item.booker.username.toUpperCase() }}
            </div>
          </template>

          <!-- ANCHOR issuer -->
          <template #cell(issuer)="data">
            <div
              v-if="data.item.issuer"
              class="mb-0 text-nowrap"
            >
              {{ data.item.issuer.username.toUpperCase() }}
            </div>
          </template>

          <!-- ANCHOR AG/CTV/Customer -->
          <template
            v-if="useForInvoice"
            #cell(customer)="{ item }"
          >
            <template v-if="showCustome(item)">
              <div>
                {{ $t('invoice.customerCode') }}:
                <span class="font-weight-bold">
                  {{ item.customer }}
                </span>
              </div>
              <div>
                {{ $t('invoice.customerName') }}:
                <span class="font-weight-bold">
                  {{ getCustomerName(item) }}
                </span>
              </div>
            </template>
            <template v-else>
              <div>
                {{ $t('agency.agencyCode') }}:
                <span class="font-weight-bold">
                  {{ item.agency }}
                </span>
              </div>
              <div>
                {{ $t('agency.agencyName') }}:
                <span class="font-weight-bold">
                  {{ getAgencyName(item) }}
                </span>
              </div>
            </template>
          </template>

          <!-- ANCHOR actions -->
          <template #cell(actions)="data">
            <div class="d-flex-center flex-nowrap">
              <b-button
                v-b-tooltip.hover.v-info.window
                variant="flat-info"
                class="p-50 mr-50"
                :title="$t('saleReport.detail.title')"
                @click="$router.push({ name: 'apps-saleReport-detail', params: { id: data.item.id} })"
              >
                <feather-icon
                  icon="EditIcon"
                  size="16"
                  style="cursor: pointer"
                />
              </b-button>

              <b-button
                v-if="data.item.isManual"
                v-b-tooltip.hover.v-danger
                title="Xoá báo cáo bán nhập thủ công"
                variant="flat-danger"
                class="p-50 mr-50"
                @click="confirmDelete(data.item.id)"
              >
                <feather-icon
                  icon="TrashIcon"
                  size="16"
                  style="cursor: pointer"
                />
              </b-button>
            </div>
          </template>

          <!-- ANCHOR sum bottom-row -->
          <template
            slot="bottom-row"
            slot-scope="data"
          >
            <td
              v-for="(field, i) in data.fields"
              :key="i"
              class="text-right"
              style="font-size: 18px"
            >
              <div v-if="visibleBottomRow.includes(field.key)">
                {{ formatCurrency(totalColumn(field.key)) || 0 }}
              </div>
              <div
                v-if="field.key === 'no'"
                class="text-uppercase"
              >
                {{ $t('saleReport.sum') }}
              </div>
            </td>
          </template>
        </b-table>

        <div
          v-if="useForInvoice"
          class="d-flex justify-content-end align-items-center"
        >
          <div>
            <div>
              <b-form-checkbox
                inline
                :checked="isCombineTickets"
                :disabled="selectionCount < 2"
                @change="isCombineTickets = !isCombineTickets"
              >
                {{ $t('invoice.combineTickets') }}
              </b-form-checkbox>
            </div>
            <div>
              <b-form-checkbox
                inline
                :checked="showTicketNumber"
                :disabled="isCombineTickets"
                @change="showTicketNumber = !showTicketNumber"
              >
                {{ $t('invoice.showTicketNumber') }}
              </b-form-checkbox>
            </div>
          </div>
        </div>
      </b-overlay>
      <!-- !SECTION -->

      <!-- ANCHOR: Table Footer -->
      <div class="mx-2 my-50">
        <b-row class="d-flex-center justify-content-md-between">
          <b-col
            cols="12"
            md="auto"
            class="d-flex align-items-center justify-content-center"
          >
            <span class="text-muted">
              {{ dataMeta.from }} {{ $t('paginationText.to') }} {{ dataMeta.to }} {{ $t('paginationText.outOf') }}
              {{ dataMeta.of }} {{ $t('paginationText.items') }}
            </span>
          </b-col>
          <b-col
            cols="12"
            md="auto"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="sizePerPage"
              first-number
              last-number
              class="mb-0 mt-25 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BOverlay,
  BTable,
  BPagination, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ref, onUnmounted, watch, computed,
} from '@vue/composition-api'

import store from '@/store'
import { SALE_REPORT_TABLE_COLUMNS, TYPE_CAN_CREATE_UPDATE_MANUALLY } from '@/constants/saleReport'
import {
  sizePerPageLgOptions,
} from '@/constants/selectOptions'
// SECTION Choose tickets
import {
  formatCurrency,
  resolveFightTypeFromTicketType,
} from '@/views/apps/invoices/useInvoice'
import {
  useChooseTicket,
  emitTicketsFromSaleReportToInvoice,
  onHandleSelectAllOrSelectNone,
  isAlreadySelected,
  // getTripsWithoutDate,
  showCustome,
  getCustomerName,
  getAgencyName,
} from '@/views/apps/invoices/invoice-popup/useChooseTicket'

import {
  replaceText,
  formatVnCurrency,
  dateTime,
  getDate,
  convertISODateTime,
} from '@core/utils/filter'

// !SECTION Choose tickets

import useSaleReportHandle, { convertShortenTrips } from '@saleReport/useSaleReportHandle'
import saleReportStoreModule from '@saleReport/saleReportStoreModule'

import SaleReportListFilters from './SaleReportListFilters.vue'

export default {
  components: {
    SaleReportListFilters,

    BCard,
    BRow,
    BCol,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    BDropdown,
    // eslint-disable-next-line vue/no-unused-components
    BDropdownItem,
    BFormCheckbox,
    BOverlay,
    BTable,
    BPagination,

    vSelect,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  props: {
    useForInvoice: {
      type: [Boolean, String],
      default: false,
    },
    initFilterIsInvoiced: {
      type: [Boolean, String],
      default: '',
    },
    saleReportsByAgency: {
      type: [Boolean, String],
      default: false,
    },
    previousSelectedTicketIds: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ticketTypeFilter() {
      if (!this.$store.hasModule('app-invoice')) return ''
      return this.$store.state['app-invoice'].ticketTypeFilter
    },

    isAlreadySelectAll() {
      if (!this.previousSelectedTicketIds.length) return false

      return this.idsList.every(id => this.previousSelectedTicketIds.includes(id))
    },
    disabledSelectAll() {
      return this.isAlreadySelectAll || !this.maxSelectionCount
    },
    isSelectAll() {
      if (this.isAlreadySelectAll) return true
      if (!this.maxSelectionCount || this.maxSelectionCount !== this.selectionCount) return false
      return this.idsCanSelect.every(id => this.selectedIds.includes(id))
    },
    canSelectMore() {
      return this.maxSelectionCount && this.maxSelectionCount > this.selectionCount
    },

    showCheckboxSelectAll() {
      if (this.isSelectAll) return true
      if (this.notSelect && this.maxSelectionCount && this.maxSelectionCount === this.idsList.length) return true
      return !this.canSelectMore || (this.notSelect && !this.previousSelectedTicketIds.length)
    },
  },

  watch: {
    ticketTypeFilter: {
      handler(val) {
        this.fixFlightType = resolveFightTypeFromTicketType(val)
      },
      immediate: true,
    },
  },

  setup(props, { emit }) {
    const SALEREPORT_APP_STORE_MODULE_NAME = 'app-sale-report'

    // Register module
    if (!store.hasModule(SALEREPORT_APP_STORE_MODULE_NAME)) {
      store.registerModule(SALEREPORT_APP_STORE_MODULE_NAME, saleReportStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SALEREPORT_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(SALEREPORT_APP_STORE_MODULE_NAME)
      }
    })

    const tableColumns = ref([])
    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    const isRoleF2 = computed(() => store.getters['userStore/getRoleF2'])
    const isRoleF3 = computed(() => store.getters['userStore/getRoleCTV'])
    const isHideFee = computed(() => store.getters['userStore/getHideFee'])

    // chỉ ADM KTT của F1 được thêm hoặc sửa báo cáo bán
    const canActionSaleReportManually = computed(() => isRoleF1.value && TYPE_CAN_CREATE_UPDATE_MANUALLY.includes(store.getters['userStore/getMeDataType']))
    if (isRoleF1.value) {
      tableColumns.value = SALE_REPORT_TABLE_COLUMNS.filter(item => !['tradeCreditors'].includes(item.key))
    } else {
      tableColumns.value = SALE_REPORT_TABLE_COLUMNS.filter(item => !['collectionFee', 'tax'].includes(item.key))
    }
    if (isRoleF3.value) {
      const removeField = ['feeIn']
      if (isHideFee.value) {
        removeField.push('feeOut')
      }
      tableColumns.value = tableColumns.value.filter(item => !removeField.includes(item.key))
    }

    function confirmExport() {
      // show modal confirm export sale report
      const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: this.$t('modal.confirmExport') } })
      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.exportSaleReport()
          }
        })
    }

    const onDebounceSearch = debounce((search, callBack) => {
      const trimSearch = search.trim()
      callBack(trimSearch)
    }, 500)

    function onRefetchData(trimSearch) {
      this.searchText = trimSearch
      this.$refs.saleReportListFilters.onUpdateFilters()
    }

    const {
      // Loading
      loading,
      // Refs
      refSaleReportListTable,

      // Filters
      sizePerPage,
      currentPage,
      dataMeta,
      sortBy,
      isSortDirDesc,
      fixFlightType,
      // Extra Filters
      searchText,

      totalItems,
      saleReportItems,
      idsList,
      selectedIds,
      selectedTickets,
      selectionCount,
      notSelect,
      isSelectedTicketsSameTrips,

      isChecked,
      toggleSelectItem,
      refetchData,
      clearFilter,

      // DISPATCH STORE
      fetchSaleReports,
      exportSaleReport,
      deleteSaleReportManually,
    } = useSaleReportHandle(props.initFilterIsInvoiced, props.saleReportsByAgency)

    // SECTION Choose tickets
    const {
      idsCanSelect,
      itemsCanSelect,
      maxSelectionCount,
      isCombineTickets,
      showTicketNumber,
    } = useChooseTicket()

    if (props.useForInvoice) {
      watch(
        notSelect,
        val => emit('update:notSelect', val),
        { immediate: true },
      )
      watch(
        idsList,
        () => {
          if (!props.previousSelectedTicketIds.length) {
            idsCanSelect.value = [...idsList.value]
            itemsCanSelect.value = [...saleReportItems.value]
          } else {
            const resultIds = []
            const resultItems = []
            idsList.value.forEach(id => {
              const index = props.previousSelectedTicketIds
                ? props.previousSelectedTicketIds.findIndex(previousSelectedId => previousSelectedId === id)
                : -1
              if (index < 0) {
                resultIds.push(id)
                resultItems.push(saleReportItems.value.find(item => item.id === id))
              }
            })

            idsCanSelect.value = resultIds
            itemsCanSelect.value = resultItems
          }
        },
        {
          immediate: true,
          deep: true,
        },
      )
    }
    // !SECTION Choose tickets

    function confirmDelete(id) {
      const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: this.$t('saleReport.confirmDelete') } })
      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            deleteSaleReportManually(id)
          }
        })
    }

    const visibleBottomRow = [
      'priceTicket', 'tax', 'otherTax',
      'collectionFee', 'feeIn', 'feeOut',
      'feeService', 'commission', 'commissionPrepaidIn',
      'commissionPrepaidOut', 'unpaidCommissionIn', 'unpaidCommissionOut', 'commission',
      'tradeCreditors', 'receivables', 'profit', 'balanceAdjustment',
    ]

    function totalColumn(key) {
      let keyTemp = key
      if (keyTemp === 'feeIn' && isRoleF2.value) keyTemp = 'feeOut'
      else if (keyTemp === 'feeOut' && isRoleF2.value) keyTemp = 'feeIn'
      return saleReportItems.value.reduce((sum, item) => sum + item[keyTemp], 0)
    }

    const getEnv = computed(() => store.getters['app/getEnv'])
    return {
      tableColumns,
      // SECTION from import
      dateTime,
      getDate,
      convertISODateTime,

      // select Options
      sizePerPageLgOptions,
      // !SECTION from import

      // SECTION useSaleReportHandle()
      // Loading
      loading,
      // Refs
      refSaleReportListTable,

      // Filters
      sizePerPage,
      currentPage,
      dataMeta,
      sortBy,
      isSortDirDesc,
      fixFlightType,
      // Extra Filters
      searchText,
      confirmDelete,

      totalItems,
      saleReportItems,

      refetchData,
      clearFilter,

      // DISPATCH STORE
      fetchSaleReports,
      exportSaleReport,
      // !SECTION useSaleReportHandle()

      // SECTION setup()
      confirmExport,
      onDebounceSearch,
      onRefetchData,
      formatVnCurrency,
      // !SECTION setup()

      // SECTION Choose tickets
      idsList,
      selectedIds,
      selectedTickets,
      selectionCount,
      notSelect,
      isSelectedTicketsSameTrips,
      idsCanSelect,
      itemsCanSelect,
      maxSelectionCount,
      isCombineTickets,
      showTicketNumber,
      isChecked,
      toggleSelectItem,
      formatCurrency,
      emitTicketsFromSaleReportToInvoice,
      onHandleSelectAllOrSelectNone,
      isAlreadySelected,
      // getTripsWithoutDate,
      showCustome,
      getCustomerName,
      getAgencyName,
      // !SECTION Choose tickets

      isRoleF2,

      // bottom-row
      totalColumn,
      visibleBottomRow,

      canActionSaleReportManually,
      replaceText,
      getEnv,

      convertShortenTrips,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.table-sales-report {
  th {
    padding-left: 6px;
    padding-right: 6px;
  }

  td {
    font-size: 14px;
    padding: 6px !important;
  }
}

.b-table-bottom-row {
  position: sticky;
  bottom: 0;
  background-color: #e1eef5;
  color: $primary;
}
</style>
